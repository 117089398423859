import api from '@/services/api'
import ApiLaudo from '@/services/laudo'
import { getField, updateField } from 'vuex-map-fields'
import { myLocalStorage } from '@/mixins/localstore'
import { saveAs } from 'file-saver'

function getFromLocalStorage (key, defaultValue) {
  const item = myLocalStorage.getItem(key);
  return item ? JSON.parse(item) : defaultValue;
}

export default {
  namespaced: true,
  state: {
    atendimento: getFromLocalStorage('atendimento', {}),
    rascunho: getFromLocalStorage('rascunho', {}),
    laudo: getFromLocalStorage('laudo', {
      cd_exame: 0,
      cd_medico: 0,
      bb_laudo: null,
      bb_html: null,
      ds_exame: '',
      sn_provisorio: false
    }),
    original: {},
    IaraConsole: '',
    iaraMinimumData: {},
    classificacao: 0,
    tipoVariaveis: 'modelo',
    headerFooter: {},
    sfdtVariaveis: '',
    layout: getFromLocalStorage('layout', {}),
    laudoSfdt: {},
    modelo: {},
    achado: {},
    complemento: {},
    listaExames: [],
    listaGrupos: [],
    listaModelos: [],
    listaModelosHtml: [],
    listaChaves: [],
    listaAchados: [],
    listaLaudoExterno: [],
    listaFiltroCancela: [],
    listaLaudoFiltroTipo: [],
    listaLaudoFiltroRevisao: [],
    listaComplementos: [],
    listaHistorico: [],
    listaModeloChave: [],
    compressoes: [],
    filmes: [],
    viewers: [],
    saveDraft: null,
  },
  getters: {
    getField
  },
  actions: {
    validarCertificado ({ commit, rootState }) {
      if (rootState.login.user.sn_certificado) {
        return api().post('se1/doMedicoToken').then(response => {
          if (!response.data.token) {
            if (response.data.url) {
              window.open(response.data.url + '/autenticarmedico', '_blank', 'noreferrer')
            } else {
              commit('layout/SET_ALERTA', { mensagem: 'Url do token não informada !' }, { root: true })
            }
          }
          return response.data.token
        }).catch(e => {
          commit('layout/SET_ALERTA', { mensagem: e.response?.data?.error || e }, { root: true })
          throw e
        })
      } else {
        return true
      }
    },
    doLaudoDados ({ commit }, payload) {
      return api().post('se1/doLaudoDados', { cd_exame: payload }).then(response => {
        if (response.data[0].bb_dados) {
          response.data[0].bb_dados = Buffer.from(response.data[0].bb_dados, 'base64'); //atob(response.data[0].bb_dados)
        }
        commit('SET_ATENDIMENTO', response.data[0])
        return response
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e.response?.data?.error || e }, { root: true })
        throw e
      })
    },
    doLaudoAbrir ({ commit }, payload) {
      return api().post('se1/doLaudoAbrir', { cd_exame: payload.exame, cd_medico: payload.medico }).then(response => {
        if (response.data) {
          commit('SET_LAUDO', response.data[0])
          commit('SET_MEDICO', payload.medico)
          return response
        } else {
          throw new Error('Laudo não encontrado !')
        }
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e.response?.data?.error || e }, { root: true })
        throw e
      })
    },
    doLaudoAgrupar ({ commit }, payload) {
      return api().post('se1/doLaudoAgrupar', { cd_exame: payload.cd_exame }).then(response => {
        commit('SET_GRUPOS', response.data)
        commit('SET_EXAMES', [payload.cd_exame])
        return response
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e.response?.data?.error || e }, { root: true })
        throw e
      })
    },
    doLaudoRevisao ({ commit }, payload) {
      return api()
        .post('se1/doLaudoRevisao', { cd_atendimento: payload.cd_atendimento }).then(response => {
          return response
        }).catch(e => {
          commit('layout/SET_ALERTA', { mensagem: e.response?.data?.error || e }, { root: true })
          throw e
        })
    },
    doLaudoGravar ({ state, commit }) {
      return api().post('se1/doLaudoGravar', state.laudo).then(response => {
        return response
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e.response?.data?.error || e }, { root: true })
        throw e
      })
    },
    doLaudoSair ({ state, commit }) {
      if (state.atendimento.cd_exame) {
        const payload = state.atendimento.cd_exame
        commit('RESET_LAUDO');
        api().post('se1/doLaudoSair', { cd_exame: payload }).catch(e => {
          commit('layout/SET_ALERTA', { mensagem: e.response?.data?.error || e }, { root: true })
          throw e
        })
      }
    },
    doLaudoCancelar ({ commit }, payload) {
      return api().post('se1/doLaudoCancelar', payload).then(response => {
        return response
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e.response?.data?.error || e }, { root: true })
        throw e
      })
    },
    doLaudoFiltroCancela ({ commit }) {
      return api().post('se1/doLaudoFiltroCancela').then(response => {
        commit('SET_LISTA_FILTRO_CANCELA', response.data || [])
        return response
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e.response?.data?.error || e }, { root: true })
        throw e
      })
    },
    doLaudoAssinar ({ state, commit }) {
      return api().post('se1/doLaudoAssinar', state.laudo).then(response => {
        return response
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e.response?.data?.error || e }, { root: true })
        throw e
      })
    },
    doLaudoAuditar ({ commit }, payload) {
      return api().post('se1/doLaudoAuditar', payload).then(response => {
        return response
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e.response?.data?.error || e }, { root: true })
        throw e
      })
    },
    doLaudoFiltroTipo ({ commit }, payload) {
      return api().post('se1/doLaudoFiltroTipo', payload).then(response => {
        commit('SET_LAUDO_FILTRO_TIPO', response.data || [])
        return response
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e.response?.data?.error || e }, { root: true })
        throw e
      })
    },
    doLaudoFiltroRevisao ({ commit }, payload) {
      return api().post('se1/doLaudoFiltroRevisao', payload).then(response => {
        commit('SET_LAUDO_FILTRO_REVISAO', response.data || [])
        return response
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e.response?.data?.error || e }, { root: true })
        throw e
      })
    },
    doLaudoProximo ({ commit }) {
      return api().post('se1/doLaudoProximo').then(response => {
        return response
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e.response?.data?.error || e }, { root: true })
        throw e
      })
    },
    doModeloLayout ({ state, commit }) {
      return api().post('se1/doModeloLayout', { cd_exame: state.atendimento.cd_exame }).then(response => {
        if (response.data.length > 0) {
          return ApiLaudo.RtfToSfd(response.data[0].bb_layout).then(response => {
            commit('SET_LAYOUT', response.data)
            return response
          })
        } else {
          return null
        }
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e.response?.data?.error || e }, { root: true })
        throw e
      })
    },
    async doModeloAbrir ({ commit }, payload) {
      try {
        const response = await api().post('se1/doModeloAbrir', { cd_modelo: payload });
        if (response.data[0]?.bb_modelo) {
          const modelo = atob(response.data[0].bb_modelo);
          let convertedModel;
          if (modelo.match(/^<html/gmi)) {
            convertedModel = await ApiLaudo.HtmlToSfd(response.data[0].bb_modelo);
          } else {
            convertedModel = await ApiLaudo.RtfToSfd(response.data[0].bb_modelo);
          }
          commit('SET_MODELO', convertedModel.data);
          return convertedModel;
        }
      } catch (e) {
        commit('layout/SET_ALERTA', { mensagem: e.response?.data?.error || e }, { root: true })
        throw e;
      }
    },
    async carregarModelo ({ dispatch, commit, rootState }, payload) {
      try {
        await dispatch('doModeloLayout');
        await dispatch('doModeloAbrir', payload);
        const response = await dispatch('doLaudoModeloChave', { cd_modelo: payload, tipo: 'modelo' });
        if (!response?.data?.length) {
          commit('SET_BODY', rootState.editor.editor);
        }
        return response;
      } catch (e) {
        commit('layout/SET_ALERTA', { mensagem: e.response?.data?.error || e }, { root: true })
        throw e;
      }
    },
    doModeloLista ({ commit, rootState }, payload) {
      return api().post('se1/doModeloLista', { cd_exame: rootState.clinux.Rotas.cd_exame, sn_todos: payload?.sn_todos, sn_html: payload?.sn_html }).then(response => {
        commit('SET_LISTA_MODELO', response.data)
        return response
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e.response?.data?.error || e }, { root: true })
        throw e
      })
    },
    doAchadoLista ({ commit }) {
      api().post('se1/doAchadoLista').then(response => {
        commit('SET_LISTA_ACHADO', response.data)
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e.response?.data?.error || e }, { root: true })
        throw e
      })
    },
    doComplementoLista ({ commit }) {
      api().post('se1/doComplementoLista').then(response => {
        commit('SET_LISTA_COMPLEMENTO', response.data)
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e.response?.data?.error || e }, { root: true })
        throw e
      })
    },
    async autoTexto ({ rootState, commit }, payload) {
      try {
        if (atob(payload.data[0]?.bb_html).includes('{\\rtf1')) {
          const response = await ApiLaudo.RtfToSfd(payload.data[0].bb_html);
          rootState.editor.editor.editor.paste(JSON.stringify(response.data));
          return response.data;
        } else if (payload.data[0].bb_html) {
          const text = atob(payload.data[0].bb_html);
          rootState.editor.editor.editor.insertText(text);
          return text;
        }
      } catch (e) {
        commit('layout/SET_ALERTA', { mensagem: e.response?.data?.error || e }, { root: true })
        throw e
      }
    },
    async doLaudoFiltroTexto ({ state, rootState, commit, dispatch }, payload = null) {
      try {
        const response = await api().post('se1/doLaudoFiltroTexto', {
          cd_exame: state.atendimento.cd_exame,
          cd_medico: rootState.login.user.cd_medico,
          ds_texto: payload
        });

        if (response.data) {
          if (response.data.length === 1 && payload !== '%') {
            if (response.data[0]?.modelo) {
              const res = await dispatch('doLaudoModeloChave', { cd_modelo: response.data[0].modelo, tipo: 'texto' });
              if (res.data?.length) {
                commit('SET_SFDT_VARIAVEIS', response)
              } else {
                dispatch('autoTexto', response)
              }
            } else {
              dispatch('autoTexto', response)
            }
          } else if (response.data.length > 0) {
            commit('SET_LISTA_CHAVE', response.data)
          } else {
            commit('SET_LISTA_CHAVE', response.data)
          }
        }
        return response;
      } catch (e) {
        commit('layout/SET_ALERTA', { mensagem: e.response?.data?.error || e }, { root: true })
        throw e
      }
    },
    doModeloExportar ({ commit }, payload) {
      api().post('se1/doModeloExportar', { cd_exame: payload.exame }).then(response => {
        return response
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e.response?.data?.error || e }, { root: true })
        throw e
      })
    },
    doMedicoAssinatura ({ commit }, payload) {
      api().post('se1/doMedicoAssinatura', { cd_medico: payload.medico }).then(response => {
        return response
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e.response?.data?.error || e }, { root: true })
        throw e
      })
    },
    doLaudoPesquisa ({ commit }, payload) {
      return api().post('se1/doLaudoLista', payload).then(response => {
        commit('SET_LAUDO', response?.data[0])
        return response?.data[0]
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e.response?.data?.error || e }, { root: true })
        throw e
      })
    },
    doLaudoLista ({ commit }, payload) {
      api().post('se1/doLaudoLista', { cd_paciente: payload.paciente, sn_assinado: true }).then(response => {
        commit('SET_HISTORICO', response.data)
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e.response?.data?.error || e }, { root: true })
        throw e
      })
    },
    doAchadoGravar ({ commit }, payload) {
      return api().post('se1/doAchadoGravar', payload).then(response => {
        return response
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e.response?.data?.error || e }, { root: true })
        throw e
      })
    },
    abrirAchado ({ commit }, payload) {
      commit('SET_ACHADO', payload)
    },
    abrirComplemento ({ commit }, payload) {
      commit('SET_COMPLEMENTO', payload)
    },
    async doComplementoGravar ({ commit }, payload) {
      try {
        const response = await api().post('se1/doComplementoGravar', payload)
        return response
      } catch (e) {
        commit('layout/SET_ALERTA', { mensagem: e.response?.data?.error || e }, { root: true })
        throw e
      }
    },
    async salvarRtf ({ commit, rootState }) {
      try {
        const exportedDocument = await rootState.editor.editor.saveAsBlob('Docx');
        const response = await ApiLaudo.SfdToRtf(exportedDocument);
        return response;
      } catch (e) {
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
      }
    },
    doDicomCompressao ({ commit }) {
      api().post('se1/doDicomCompressao').then(response => {
        commit('SET_COMPRESSOES', response.data)
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e.response?.data?.error || e }, { root: true })
        throw e
      })
    },
    async doLaudoVisualizar ({ commit }, payload) {
      try {
        const response = await api().post('se1/doLaudoVisualizar', { cd_exame: payload });
        const rtf = response.data[0]?.bb_laudo ? atob(response.data[0].bb_laudo) : null;
        if (rtf?.includes('{\\rtf1')) {
          const responseRtf = await ApiLaudo.RtfToSfd(response.data[0].bb_laudo);
          if (responseRtf?.data.sections[0]) {
            commit('SET_LAUDO_SFDT', responseRtf.data);
          }
          return responseRtf;
        }
      } catch (e) {
        commit('layout/SET_ALERTA', { mensagem: e.response?.data?.error || e }, { root: true })
        throw e;
      }
    },
    doDicomFilme ({ commit, state }) {
      api().post('se1/doDicomFilme', {
        cd_atendimento: state.atendimento.cd_atendimento,
        nr_controle: state.atendimento.nr_controle,
        cd_exame: state.atendimento.cd_exame
      }).then(response => {
        commit('SET_FILMES', response.data)
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e.response?.data?.error || e }, { root: true })
        throw e
      })
    },
    async doDicomDownloadLink ({ commit, rootState }, payload) {
      const params = {
        cd_atendimento: payload.cd_atendimento,
        cd_exame: payload.cd_exame,
        cd_filme: payload.cd_filme,
        cd_compressao: payload.cd_compressao,
        nr_controle: payload.nr_controle,
        token: rootState.login.user.ds_token
      }
      const url = api().defaults.baseURL
      const urlParams = new URLSearchParams(params).toString()
      const urlDownload = `${url}/www/doGetDicom?${urlParams}`

      try {
        const response = await api().get(urlDownload, {
          responseType: 'blob',
          timeout: 0,
          onDownloadProgress: (progressEvent) => {
            commit('anexos/SET_MODO', 'Download', { root: true })
            commit('anexos/SET_DS_ARQUIVO', 'Imagem do atendimento', { root: true })
            commit('anexos/SET_PORCENTAGEM', parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100)), { root: true })
          }
        })
        commit('anexos/RESET_PROGRESSO', {}, { root: true })
        saveAs(response.data, `${payload.nr_controle}.zip`)
      } catch (e) {
        commit('anexos/RESET_PROGRESSO', {}, { root: true })
        commit('layout/SET_ALERTA', { mensagem: e.response?.data?.error || e }, { root: true })
        throw e
      }
    },
    carregarViewers ({ commit, rootState }) {
      // console.log('carregarViewer', navigator)
      const setup = rootState.login.setup;
      const snMacOs = true //navigator.userAgent.match(/\(mac/gmi);
      const viewers = [
        { icone: 'mdi-image-area', texto: 'Mobile', acao: 'mobile', exibir: setup.sn_dicomvix_mobile },
        { icone: 'mdi-image-area-close', texto: 'Jpeg', acao: 'jpeg', exibir: setup.sn_dicomvix_filme },
        { icone: 'mdi-image-area-close', texto: 'Dicom (Java)', acao: 'weasis', exibir: setup.sn_dicomvix_dicom },
        { icone: 'mdi-image-area-close', texto: 'Dicom (Web)', acao: 'ohif', exibir: setup.sn_dicomvix_dicom },
        { icone: 'mdi-image-area-close', texto: 'Dicom (Radiant)', acao: 'radiant', exibir: setup.sn_dicomvix_dicom },
        { icone: 'mdi-image-area', texto: 'DicomVix Mac', acao: 'osirix', exibir: snMacOs && setup.sn_dicomvix_wado },
        { icone: 'mdi-image-area', texto: 'DicomVix Mac Open', acao: 'osirix-open', exibir: snMacOs && setup.sn_dicomvix_wado },
        { icone: 'mdi-image-area', texto: 'DicomVix Mac Click', acao: 'osirix-onclick', exibir: snMacOs && setup.sn_dicomvix_wado }
      ].filter(v => v.exibir)
      commit('SET_VIEWERS', viewers);
    },
    doFuncionarioAcesso ({ rootState, commit }) {
      return api().post('se1/doFuncionarioAcesso', {
        cd_funcionario: rootState.login.user.cd_funcionario,
        ds_form: 'GER_MEDICO_EXE_LAUDO'
      }).then(response => {
        return response.data.length === 0
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e.response?.data?.error || e }, { root: true })
        throw e
      })
    },
    async doLaudoModeloChave ({ commit }, payload) {
      try {
        const response = await api().post('se1/doLaudoModeloChave', { cd_modelo: payload.cd_modelo })
        if (response.data?.length) {
          const chaves = response.data
          commit('SET_LISTA_MODELO_CHAVE', chaves)
          commit('SET_TIPO_VARIAVEIS', payload.tipo)
        } else if (payload.tipo === 'texto') {
          commit('layout/SET_ALERTA', { mensagem: 'Lista de Variáveis Vazia !' }, { root: true })
          return false
        }
        return response
      } catch (e) {
        commit('layout/SET_ALERTA', { mensagem: e.response?.data?.error || e }, { root: true })
        throw e
      }
    },
    doLaudoModeloChaveLista ({ commit }, payload) {
      return api().post('se1/doLaudoModeloChaveLista', {
        cd_chave: payload
      }).then(response => {
        if (response.data.errorr || response.data[0]?.Falha) {
          this.$store.dispatch('layout/definirAlerta', { ativo: true, mensagem: response.data.errorr || response.data[0]?.Falha })
          return null
        }
        return response.data || []
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e.response?.data?.error || e }, { root: true })
        throw e
      })
    },
    doLaudoExternoLista ({ commit }, payload) {
      return api().post('se1/doLaudoExternoLista', {
        cd_exame: payload
      }).then(response => {
        if (response.data.errorr || response.data[0]?.Falha) {
          commit('layout/SET_ALERTA', { mensagem: response.data.errorr || response.data[0]?.Falha }, { root: true })
        } else {
          commit('SET_LISTA_LAUDO_EXTERNO', response.data || [])
        }
        return response
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e.response?.data?.error || e }, { root: true })
        throw e
      })
    },

    fluxoRascunho ({ commit, rootState, state }, payload) {
      const saveDraft = () => {
        const rascunho = {
          cd_exame: state.atendimento.cd_exame,
          bb_laudo: rootState.editor.editor ? rootState.editor.editor.serialize() : null,
          bb_reset: state.original
        }
        if (!rootState.laudite.inExecution)
          commit('SET_RASCUNHO', rascunho)
      }
      if (payload) {
        window.addEventListener('beforeunload', saveDraft, false)
      } else {
        window.removeEventListener('beforeunload', saveDraft)
      }
    },
    doCadastroChave ({ commit }, payload) {
      return api().post('se1/doCadastro', payload).then(response => {
        return response
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e.response?.data?.error || e }, { root: true })
        throw e
      })
    },
    doMedicoToken ({ commit }) {
      return api().post('se1/doMedicoToken').then(response => {
        return response
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e.response?.data?.error || e }, { root: true })
        throw e
      })
    }

  },
  mutations: {
    updateField,
    SET_GRUPOS (state, payload) {
      state.listaGrupos = payload
    },
    SET_EXAMES (state, payload) {
      state.listaExames = payload
    },
    SET_COMPRESSOES (state, payload) {
      state.compressoes = payload
    },
    SET_FILMES (state, payload) {
      state.filmes = payload
    },
    SET_ATENDIMENTO (state, payload) {
      state.atendimento = payload
      state.listaModelos = []
      state.listaChaves = []
      myLocalStorage.setItem('atendimento', JSON.stringify(payload))
    },
    SET_LAUDO (state, payload) {
      if (payload) {
        state.laudo = payload
        myLocalStorage.setItem('laudo', JSON.stringify(payload))
      } else {
        state.laudo = {
          cd_exame: 0,
          cd_medico: 0,
          bb_laudo: null,
          bb_html: null,
          ds_exame: '',
          sn_provisorio: false
        }
        myLocalStorage.removeItem('laudo')
      }
    },
    SET_MEDICO (state, payload) {
      state.laudo.cd_medico = payload
    },
    SET_LISTA_MODELO (state, payload) {
      state.listaModelos = payload
    },
    SET_LISTA_MODELO_HTML (state, payload) {
      state.listaModelosHtml = payload
    },
    SET_LISTA_ACHADO (state, payload) {
      state.listaAchados = payload
    },
    SET_LISTA_MODELO_CHAVE (state, payload) {
      state.listaModeloChave = payload
    },
    SET_LISTA_FILTRO_CANCELA (state, payload) {
      state.listaFiltroCancela = payload
    },
    SET_LAUDO_FILTRO_TIPO (state, payload) {
      state.listaLaudoFiltroTipo = payload
    },
    SET_LAUDO_FILTRO_REVISAO (state, payload) {
      state.listaLaudoFiltroRevisao = payload
    },
    SET_TIPO_VARIAVEIS (state, payload) {
      state.tipoVariaveis = payload
    },
    SET_LISTA_COMPLEMENTO (state, payload) {
      state.listaComplementos = payload
    },
    SET_LISTA_CHAVE (state, payload) {
      state.listaChaves = payload
    },
    SET_VIEWERS (state, payload) {
      state.viewers = payload
    },
    SET_LAUDO_SFDT (state, payload) {
      state.laudoSfdt = payload
    },
    SET_HISTORICO (state, payload) {
      state.listaHistorico = payload
    },
    // SET_AUTO_TEXTO (state, payload) {
    //  state.autotexto = payload
    // },
    SET_LAYOUT (state, payload) {
      state.layout = payload
      myLocalStorage.setItem('layout', JSON.stringify(payload))
    },
    SET_MODELO (state, payload) {
      state.modelo = payload
    },
    SET_ACHADO (state, payload) {
      state.achado = payload
    },
    SET_COMPLEMENTO (state, payload) {
      state.complemento = payload
    },
    SET_SFDT_VARIAVEIS (state, payload) {
      state.sfdtVariaveis = payload
    },
    SET_LISTA_LAUDO_EXTERNO (state, payload) {
      state.listaLaudoExterno = payload
    },
    SET_BODY (state, payload) {
      payload.openBlank()
      if (state.layout?.sections) {
        /*
        if (state.layout.sections[0].headersFooters?.firstPageHeader) {
          state.layout.sections[0].headersFooters.header = state.layout.sections[0].headersFooters.firstPageHeader
          delete state.layout.sections[0].headersFooters.firstPageHeader
        }
        */
        // Força espaçamento do modelo conforme definido no layout
        state.modelo.sections.forEach(section => {
          section.sectionFormat = state.layout?.sections[0]?.sectionFormat || section.sectionFormat
        })

        // payload.sfdt = {}
        payload.open(state.layout)

        if (payload.selection) {
          Promise.resolve(payload.sfdt).then(() => {
            payload.editor.paste(JSON.stringify(state.modelo))
          })
        } else {
          payload.editor.paste(JSON.stringify(state.modelo))
        }
      }
    },
    SET_RASCUNHO (state, payload) {
      console.log('rascunho salvo')
      state.rascunho = payload
      myLocalStorage.setItem('rascunho', JSON.stringify(payload))
    },/*
    SET_RECOGNITION (state, payload) {
      state.recognition = payload
    },
    RESET_RECOGNITION (state) {
      state.recognition = null
    },*/
    RESET_LAUDO (state) {
      state.classificacao = 0
      state.laudo = {}
      state.achado = {}
      state.complemento = {}
      state.atendimento = {}
      state.layout = {}
      state.modelo = {}
      state.listaHistorico = []
      state.listaModelos = []
      state.listaChaves = []
      // myLocalStorage.removeItem('filtro')
      myLocalStorage.removeItem('laudo')
      myLocalStorage.removeItem('sfdt')
    },
  }
}
