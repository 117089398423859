// import axios from 'axios'
import { myLocalStorage } from '@/mixins/localstore'
// import { getField, updateField } from 'vuex-map-fields'
import ApiLaudo from '@/services/laudo'
// import ApiTools from '@/services/wptools'
import CryptoJS from 'crypto-js'
import { format } from 'date-fns';

export default {
  namespaced: true,
  state: {
    urlLaudite: 'https://app.laudite.com.br',
    companyToken: '',
    date: format(new Date(), 'yyyyMMdd'),
    //viewMode: myLocalStorage.getItem('laudite.view'),
    inExecution: '', // myLocalStorage.getItem('laudite.exec'),
    proximo: false,
    existeListener: false,
    iframe: null,
    eventEmit: null,
    url: '',
    // html: myLocalStorage.getItem('laudite.html'),
  },
  actions: {
    makeUrl ({ commit, state, rootState }) {
      const pass = state.companyToken + state.date
      const iv = pass.slice(-16)

      const CipherJS = {
        pass: CryptoJS.enc.Utf8.parse(pass),
        iv: CryptoJS.enc.Utf8.parse(iv),
        encript (msg) {
          try {
            const options = { mode: CryptoJS.mode.CBC, iv: this.iv }
            const json = CryptoJS.AES.encrypt(msg, this.pass, options)
            return json.ciphertext.toString(CryptoJS.enc.Hex)
          } catch (err) {
            console.error(err)
            return ''
          }
        },
        decript (hex) {
          try {
            const options = { mode: CryptoJS.mode.CBC, iv: this.iv }
            const json = CryptoJS.AES.decrypt({
              ciphertext: CryptoJS.enc.Hex.parse(hex)
            }, this.pass, options)
            return json.toString(CryptoJS.enc.Utf8)
          } catch (err) {
            console.error(err)
            return ''
          }
        }
      }
      try {
        console.log(rootState.login.user.ds_email)
        const user = CipherJS.encript(rootState.login.user.ds_email)
        const mask = (state.companyToken === '60dc9862e35cb25c26cdbc92') ? 'both' : 'onlyCompany'
        const url = `${state.urlLaudite}/?company=${state.companyToken}&user=${user}&view=${state.modeView || 'slim'}&masksScope=${mask}`
        console.log(url)
        commit('SET_URL', url)
      } catch (error) {
        commit('layout/SET_ALERTA', { mensagem: 'Para utilizar o editor Laudite, é necessário possuir e-mail no cadastro do médico.' }, { root: true })
      }
    },

    startEvents ({ dispatch }) {
      dispatch('makeUrl')
      dispatch('getData')
    },

    sendData ({ state }, payload) {
      console.log('sendData', payload)
      return state.iframe?.contentWindow
        ? Promise.resolve(state.iframe.contentWindow.postMessage(payload, state.urlLaudite))
        : {}
    },

    getData ({ state, commit, dispatch }) {
      if (state.existeListener)
        return
      console.log('getData')
      if (window.addEventListener) {
        window.addEventListener('message', e => dispatch('handlePostMessage', e), false)
      } else if (window.attachEvent) { // ie8
        window.attachEvent('onmessage', e => dispatch('handlePostMessage', e))
      }
      commit('SET_LISTENER', true)
    },

    handlePostMessage ({ dispatch }, payload) {
      const actions = ['saveReport', 'endReport', 'nextReport', 'displayMode', 'loaded', 'sendMask', 'newReport'] //'companyMasks',
      if (payload?.data && actions.includes(payload?.data?.action)) {
        try {
          console.log('handlePostMessage', payload?.data?.action)
          dispatch(payload.data.action, payload.data.data || null)
        } catch (e) {
          console.error(e)
        }
      }
    },

    async htmlToSfdt ({ rootState }, payload) {
      if (!payload) {
        console.error('Payload não informado')
        return false
      }
      try {
        const response = await ApiLaudo.HtmlToSfd(payload);
        if (!response.data) {
          console.error('Html retornou em branco');
          return false;
        }
        const htmlBody = JSON.stringify(response.data)
        if (!htmlBody) {
          console.erro('Layout não encontrado')
          return false;
        }
        const ed = rootState.editor;
        // console.log(ed.editor)
        if (rootState.laudo.layout) {
          ed.editor.open(rootState.laudo.layout);
          ed.editor.editor.paste(htmlBody);
          ed.editor.selection?.moveToDocumentStart()
          ed.editor.selection?.selectCurrentWord()
          if (ed.editor.selection.text.trim() === '')
            ed.editor.editor.delete()
          ed.editor.editor.selection.selectAll();
          ed.editor.editor.selection.characterFormat.fontColor = '#000000';
          return true;
        } else {
          console.erro('Layout não encontrado')
          return false;
        }
      } catch (e) {
        console.error(e);
        return false;
      }
    },

    async newReport ({ dispatch, rootState }) {
      console.log('newReport', rootState.clinux.Rotas.cd_exame)
      // if (rootState.editor.html) {
      //   dispatch('sendData', {
      //     action: 'sendMask',
      //     data: rootState.editor.html
      //   });
      //   commit('editor/SET_HTML', '', { root: true })
      // } else {
      //   dispatch('companyMasks')
      // }
      dispatch('companyMasks')
    },

    saveReport ({ dispatch, commit }, payload) {
      if (payload) {
        dispatch('htmlToSfdt', payload).then(bool => {
          if (bool) commit('SET_EVENT_EMIT', 'salvar')
        })
      } else {
        commit('layout/SET_ALERTA', { mensagem: 'O laudo precisa estar preenchido para continuar.' }, { root: true })
      }
    },

    endReport ({ dispatch, commit }, payload) {
      if (payload) {
        dispatch('htmlToSfdt', payload).then(bool => {
          if (bool) commit('SET_EVENT_EMIT', 'assinar')
        })
      } else {
        commit('layout/SET_ALERTA', { mensagem: 'O laudo precisa estar preenchido para continuar.' }, { root: true })
      }
    },

    nextReport ({ dispatch, commit }, payload) {
      if (payload) {
        dispatch('htmlToSfdt', payload).then(bool => {
          if (bool) commit('SET_EVENT_EMIT', 'proximo')
        })
      } else {
        commit('layout/SET_ALERTA', { mensagem: 'O laudo precisa estar preenchido para continuar.' }, { root: true })
      }
    },

    displayMode ({ commit }, payload) {
      // if (payload) {
      //   commit('SET_VIEW_MODE', payload)
      // }
      if (payload === 'hidden') {
        commit('SET_IN_EXECUTION', false)
        window.dispatchEvent(new Event('resize'))
      }
    },

    companyMasks ({ dispatch, rootState }) {
      // Verifica se cd_exame existe
      if (!rootState.clinux.Rotas.cd_exame) return;

      // Chama a ação doModeloLista
      dispatch('laudo/doModeloLista', { sn_html: true }, { root: true }).then(() => {
        // Mapeia a lista de modelos para um novo formato
        const modelos = rootState.laudo.listaModelos?.map(({ cd_modelo, ds_modelo, bb_html }) => ({
          id: cd_modelo,
          title: ds_modelo,
          html: atob(bb_html)
        })) || [];

        // Envia os dados
        dispatch('sendData', {
          action: 'companyMasks',
          data: modelos
        });
      });
    },

    loaded ({ commit }) {
      console.log('loaded')
      const lauditeIfr = document.querySelector('.lauditeIframe')
      try {
        commit('SET_IFRAME', lauditeIfr)
      } catch (err) {
        console.error(err)
      }
    },
  },
  mutations: {
    // updateField,
    // SET_VIEW_MODE (state, payload) {
    //   myLocalStorage.setItem('laudite.view', payload)
    //   state.viewMode = payload
    // },
    SET_IN_EXECUTION (state, payload) {
      myLocalStorage.setItem('laudite.exec', payload)
      state.inExecution = payload
    },
    SET_EVENT_EMIT (state, payload) {
      state.eventEmit = payload
    },
    SET_IFRAME (state, payload) {
      state.iframe = payload
    },
    // SET_HTML (state, payload) {
    //   myLocalStorage.setItem('laudite.html', payload)
    //   state.html = payload
    // },
    SET_URL (state, payload) {
      state.url = payload
    },
    SET_LISTENER (state, payload) {
      state.existeListener = payload
    },
    SET_PROXIMO (state, payload) {
      state.proximo = payload
    },
    SET_TOKEN (state, payload) {
      if (payload) {
        state.companyToken = '5f4ccc9e1b0f137dfdea7bba' // telelaudo
      } else {
        state.companyToken = '60dc9862e35cb25c26cdbc92' // Genesis
      }
    }
  }
}
